
export default {
  name: 'NotificationComponent',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 3,
    },
    showExit: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      showNotification: false,
    }
  },
  watch: {
    isShow(val) {
      if (val) {
        this.showNotification = true

        if (this.timeout !== 0) {
          setTimeout(() => {
            this.showNotification = false
            this.$emit('update:isShow', false)
          }, this.timeout * 1000)
        }
      }
    },
  },
}
