
import { mapActions } from 'vuex'

import Input from '~/components/ui/Input.vue'
import Checkbox from '~/components/ui/Checkbox.vue'
import RadioButton from '~/components/ui/RadioButton.vue'
import Notification from '~/components/ui/Notification.vue'
import Select from '~/components/ui/Select.vue'
import Button from '~/components/ui/Button.vue'

import { generateDOBDefaultValue, toTimestamp } from '~/utils/date'
import encrypt from '~/utils/encrypt'

export default {
	components: { Input, Checkbox, RadioButton, Notification, Select, Button },
	layout: 'auth',
	auth: 'guest',
	asyncData({ app }) {
		app.$cache.route.setCacheable()
	},
	data() {
		return {
			loading: false,
			isAccept: false,
			loginData: {
				email: '',
				password: ''
			},
			registerData: {
				email: '',
				password: '',
				repeatPassword: '',
				gender: 0,
				firstName: '',
				lastName: ''
			},
			successLogin: false,
			isIdenticalPass: false,
			DOBYear: '',
			DOBMonth: '',
			DOBDay: '',
			birthday: {}
		}
	},
	head({ $seo, $config, $route }) {
		return $seo({
			title: `Логін | ${$config.projectName}`,
			robots: 'noindex, nofollow',
			openGraph: {
				title: `Логін | ${$config.projectName}`,
				url: `${$config.projectURL}${$route.fullPath}`
			},
			twitter: {
				title: `Логін | ${$config.projectName}`
			}
		})
	},
	mounted() {
		this.birthday = generateDOBDefaultValue(this.DOBYear, this.DOBMonth)
	},
	methods: {
		...mapActions({
			registration: 'userAuth/register'
		}),
		async login() {
			this.loading = true

			try {
				const clientHash = encrypt(this.loginData, this.$config.projectSecret)
				const data = {
					data: { ...this.loginData, client_hash: clientHash }
				}

				const response = await this.$auth.loginWith('local', data)

				if (response.status === 200) {
					this.successLogin = true
				} else this.$toast.error(response.data.err)
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},

		async register() {
			if (this.registerData.password !== this.registerData.repeatPassword) {
				this.isIdenticalPass = true
			} else {
				try {
					this.loading = true
					const clientHash = encrypt(this.loginData, this.$config.projectSecret)

					const data = {
						email: this.registerData.email,
						password: this.registerData.password,
						password_confirm: this.registerData.repeatPassword,
						first_name: this.registerData.firstName,
						last_name: this.registerData.lastName,
						gender: this.registerData.gender,
						client_hash: clientHash,
						birth_date: `${toTimestamp(
							this.DOBYear,
							this.DOBMonth,
							this.DOBDay
						)}`
					}
					const response = await this.registration(data)

					if (response.status === 200) {
						this.$auth.setUserToken(response.data.token)
						return await this.$router.push('/')
					} else {
						this.$toast.error(response.data.err)
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}
			}
		}
	}
}
