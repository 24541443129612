import moment from 'moment'

export const generateDOBDefaultValue = (year, month) => {
	const currentYear = moment().year()
	const fromYear = currentYear - 18 - 100
	const selectedMonth = month
	const selectedYear = year

	return {
		year: Array.from(
			{ length: 100 },
			(x, i) => `${fromYear + i + 1}`
		).reverse(),
		day: Array.from(
			{
				length: moment(
					`${selectedYear || currentYear}-${
						selectedMonth || moment().month() + 1
					}`,
					'YYYY-MM'
				).daysInMonth()
			},
			(x, i) => moment().startOf('month').add(i, 'days').format('DD')
		),
		month: Array.from({ length: 12 }, (x, i) => moment().month(i).format('MM'))
	}
}

export const getUserDate = data => {
	const date = moment(data)

	return {
		day: date.format('DD'),
		month: date.format('MM'),
		year: date.format('YYYY')
	}
}

export const toTimestamp = (year, month, day) =>
	moment(`${year}-${month}-${day}`).format()
