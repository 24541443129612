import sha256 from 'crypto-js/sha256'

export default (sorted, secret) => {
  function makeString(object, resultString) {
    const values = Object.values(object)
    values.forEach((item) => {
      if ((item && item !== '') || item === 0) {
        if (typeof item === 'object') {
          resultString += makeString(item, '')
        } else {
          resultString += item.toString()
        }
      }
    })
    return resultString
  }

  let dataToEnc = ''
  if (secret) {
    dataToEnc = makeString(sorted, '').concat(secret)
  }
  const encData = sha256(dataToEnc)
  return encData.toString()
}
